import { devConsoleWarn } from '../_static/js/logger'

/**
 * CookieBot dark-side js part of CookieBot.elm port module
 */

/**
 * Automatically binds abstract Elm app which is using CookieBot module with all necessary callbacks
 * NOTE: this function shouldn't be async loaded/bundled since the cookieBot initialization is triggered within
 * Elm itself as one of the very first things
 *
 * @param { AppWithPorts<CookieBotPortInterface> } app - ElmApp instance
 */
export function bindElmApp(app) {
  app.ports.cookieBotPort_.subscribe((portValue) => {
    switch (portValue.type_) {
      case 'Init' :
        init(portValue.args)
          .then(() => {
            try {
              app.ports.cookieBotSubscription_.send(
                {
                  consent: getCurrentConsent(),
                  type_: 'OnConsentUpdate'
                })
            } catch (err) {
              devConsoleWarn('CookieBot@InitPort',
                'cookieBotSubscription_ is not called in Elm code')
            }
          })
        break
    }

    onCookieBotLoad()
      .then((consent) => {
        app.ports.cookieBotSubscription_.send(
          {
            consent: consent,
            type_: 'OnConsentUpdate'
          })
      })
      .catch(() => {
        devConsoleWarn('CookieBot@onCookieBotLoad', 'cookieBotSubscription_ is not called in Elm code')
      })
  })
}

/**
 * Adds callbacks to window global object.
 * These callbacks are directly sync-invoked by CookieBot code so are faster (and sync run) than window events
 *
 * @param { string } dialogContainerId - dialog container id value
 */
function addWindowCallbacks(dialogContainerId) {
  // eslint-disable-next-line camelcase
  window.CookiebotCallback_OnDialogDisplay = (function () {
    /**
     * This is the first callback which can be called right after the cookie-bot dialog container have been added as body
     * first child. It can be considered a safe entry point to apply dom-manipulation before the next Elm repaint cycle
     */
    // console.log('time at dialog display callback', window.performance.now())
    // console.log('body', Object.assign({}, document.body.childNodes))
    moveCookieBotDialog(dialogContainerId)
  }).bind(dialogContainerId)

  // eslint-disable-next-line camelcase
  window.CookiebotCallback_OnDialogInit = function () {
    /**
     * despite the name is called far away before the dialog element is effectively added to the DOM
     */
    // devConsoleLog('time at dialog init callback', window.performance.now())
    // devConsoleLog('body', Object.assign({}, document.body.childNodes))
  }
}

/**
 * Moves the cookie consent dialog as the last child
 *
 * @param { string } dialogContainerId - id of the container
 */
function moveCookieBotDialog(dialogContainerId) {
  const dialogContainer =
      document.getElementById(dialogContainerId)
  if (dialogContainer) {
    // Moves the node from 1st to last DOM element
    document.body.appendChild(dialogContainer)
  }
}

/**
 * load cookie bot for me in async way
 *
 * @param { BootArgs } args - Arguments
 * @returns Promise<void>
 */
export function init(args) {
  return new Promise((resolve, reject) => {
    if ('Cookiebot' in window) {
      resolve()
    } else {
      // Adds sync callbacks into window object
      addWindowCallbacks(args?.dialogContainerId || 'CybotCookiebotDialog')
      // Instantiate the promise right now to be sure to don't miss it
      const scriptNode = document.createElement('script')
      scriptNode.setAttribute('id', 'Cookiebot')
      scriptNode.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
      scriptNode.setAttribute('data-cbid', args.cookieBotId)
      scriptNode.setAttribute('async', 'true')
      scriptNode.onload = () => resolve()
      scriptNode.onerror = () => reject()

      const oldNode = document.getElementById('Cookiebot')
      oldNode ? oldNode.replaceWith(scriptNode) : document.head.appendChild(scriptNode)
    }
  }
  )
}

/**
 * On dialog init event listener
 *
 * @returns Promise<Event> - a full-filled promise once the Cookiebot dialog have been initialized
 */
export function onDialogInit() {
  return new Promise((resolve) => {
    window.addEventListener('CookiebotOnDialogInit', (arg) => {
      resolve(arg)
    })
  })
}

/**
 * On dialog show event listener
 *
 * @returns Promise<Event> - a full-filled promise once the Cookiebot dialog have been displayed to the user
 */
export function onDialogDisplay() {
  return new Promise((resolve) => {
    window.addEventListener('CookiebotOnDialogDisplay', (arg) => {
      resolve(arg)
    })
  })
}

/**
 * Retrieves the current consent state in a sync way.
 * It's defaulted to necessary only whenever Cookiebot consent is not available so bind to 'onCookieBotLoad' to be notified
 * whenever the consent is changed
 *
 * @returns CookieBotConsent - a valid CookieBotConsent object
 */
export function getCurrentConsent() {
  return {
    marketing : window.Cookiebot?.consent.marketing || false,
    necessary : window.Cookiebot?.consent.necessary || true,
    preferences : window.Cookiebot?.consent.preferences || false,
    statistics : window.Cookiebot?.consent.statistics || false
  }
}

/**
 * This will be triggered ONLY when the user had made a CB choice (previous or directly interacting with the bar).
 *
 * @returns Promise<CookieBotConsent> - a promise with consent object
 */
export function onCookieBotLoad() {
  return new Promise((resolve) => {
    window.addEventListener('CookiebotOnLoad', () => {
      resolve(getCurrentConsent())
    })
  })
}