import Cookie from "js-cookie";

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const referralHomeByCookie = () => {
  let referral = Cookie.get("referral_home");

  if (referral != null) {
    return referral;
  }
  return null;
};

const referralHomeByQueryParam = () => {
  let referral = getParameterByName("referral");

  if (referral !== null && referral !== "" && referral !== undefined) {
    return referral;
  }

  return null;
};

export const getReferral = () => {
  return referralHomeByQueryParam() || referralHomeByCookie();
};
