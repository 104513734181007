import { Flagship, LogLevel} from '@flagship.io/js-sdk'
import { devConsoleLog, devConsoleError } from "../_static/js/logger";
import {getUserRole} from "../_static/js/analytics_handler";

/**
 * @class {FlagshipIO} FlagshipIO
 */
export class FlagshipIO {
  instance

  visitorReadyPromise

  logHitSending

  /**
   * Wraps flagship start method in a class constructor which ensure to initialize a visitor
   *
   * @param {{apiKey: *, logHitSending: boolean, envId: *, visitorInitialContext: {isMobile: *}, visitorId: string}} args - constructor argument record
   */
  constructor(args) {
    this.instance = Flagship.start(args.envId, args.apiKey, {
      ...args.config,
      logLevel: LogLevel.WARNING,
      onBucketingFail: function (error) {
        devConsoleError('FlagshipIO:bucketing failure', error)
      },
      onBucketingSuccess: function ({status, payload}) {
        devConsoleLog('FlagshipIO:bucketing success', status, payload)
      },
      onBucketingUpdated: function (lastUpdate) {
        devConsoleLog('FlagshipIO:bucketing updated', lastUpdate)
      },
      onLog: (level, tag, message) => {
        devConsoleLog('FlagshipIO:log', `[${LogLevel[level]}] [${tag}] : ${message}`)
      },
      statusChangedCallback: function (status) {
        devConsoleLog('FlagshipIO:status changed callback', status)
      }
    })
    this.logHitSending = args.logHitSending || false

    this.visitorReadyPromise = new Promise((resolve, reject) => {
      const v = this.instance.newVisitor({
        context: {
          ...args.visitorInitialContext,
          userAgent: navigator.userAgent,
          userRole: getUserRole() || undefined
        },
        hasConsented: true, // We consider a technical consent
        isAuthenticated: false,
        visitorId: args.visitorId,
      })

      v.on('ready', async (error) => {
        if (error) {
          devConsoleError('FlagshipIO:visitor ready error', error)
          reject(`Visitor ready error: ${error}`)
        }
        resolve(v)
      })
    })
  }

  /**
   * Fetch the flag from Flagship. Wraps the stuff into an async promise due to initial fetchFlags method.
   * Once the class have been correctly instanced with a visitor this method will never fail
   *
   * @param flagName - a flag name eg: "button-color"
   * @param fallbackValue - a fallback error returned in case of error eg: "red"
   * @returns a T value wrapped into flagship IFlag type
   */
  async getFlag(flagName, fallbackValue) {
    try {
      const v = await this.visitorReadyPromise

      return v.getFlag(flagName, fallbackValue).getValue()
    } catch (e) {
      devConsoleError('FlagshipIO:getFlag', e)

      return fallbackValue
    }
  }

  /**
   * Wraps internal flagship updateContext into a promise
   *
   * @param context -  a generic JSON object with no nesting
   */
  async updateContext(context) {
    devConsoleLog('FlagshipIO:updateContext', context)

    const v = await this.visitorReadyPromise
    v.updateContext(context)
    await v.fetchFlags()
  }

  /**
   * Wraps internal flagship send hit
   *
   * @param hit - flagship internal hit type
   */
  async sendInternalHit(hit) {
    if (this.logHitSending) {
      console.log('FlagshipIO:sendInternalHit', hit)
    }
    const v = await this.visitorReadyPromise
    await v.sendHit(hit)
      .then(() => {
        if (this.logHitSending) {
          console.log('FlagshipIO:sendInternalHit sent')
        }
      })
      .catch((err) => {
        devConsoleError('FlagshipIO:sendInternalHit', err)
      })
  }

  /**
   * Wraps internal flagship send hits
   *
   * @param hits -  a list of flagship internal hit type
   */
  async sendInternalHits(hits) {
    if (this.logHitSending) {
      console.log('FlagshipIO:sendInternalHits', hits)
    }
    const v = await this.visitorReadyPromise
    await v.sendHits(hits)
      .then(() => {
        if (this.logHitSending) {
          console.log('FlagshipIO:sendInternalHits sent')
        }
      })
      .catch((err) => {
        devConsoleError('FlagshipIO:sendInternalHits', err)
      })
  }

  /**
   * Mount elm App ports
   *
   * @param app - The App which implements FlagshipIO module
   */
  mount(app) {
    app.ports.flagshipIOPort_.subscribe((portValue) => {
      switch (portValue.type_) {
        case 'send_hit': {
          this.sendInternalHit(portValue.hit)
            .then()
            .catch((err) => {
              devConsoleError('FlagshipIO:flagshipIOPort_ send_hit', err)
            })
          break
        }
        case 'send_hits': {
          this.sendInternalHits(portValue.hits)
            .then()
            .catch((err) => {
              devConsoleError('FlagshipIO:flagshipIOPort_ send_hits', err)
            })
          break
        }
        case 'update_context': {
          this.updateContext(portValue.context)
            .then()
            .catch((err) => {
              devConsoleError('FlagshipIO:flagshipIOPort_ update_context', err)
            })
          break
        }

        default: {
          devConsoleError('FlagshipIO:flagshipIOPort_ unrecognized portValue type', portValue)
          break
        }
      }
    })

  }
}
