import { datadogRum } from '@datadog/browser-rum'
import { isStagingNodeEnv, isProductionNodeEnv } from './utils'

function isDatadogEnabled() {
  return (isStagingNodeEnv()||isProductionNodeEnv())
}

/**
 * Boots datadog logger
 */
export function bootDatadog({ applicationId, clientToken, service, env, version, enableSessionTracking = false }) {
  if (isDatadogEnabled()) {
    datadogRum.init({
      applicationId: applicationId,
      clientToken: clientToken,
      env: env,
      sampleRate: 100,
      service:service,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      site: 'datadoghq.eu',
      trackInteractions: true,
      trackLongTasks: true,
      defaultPrivacyLevel:'mask',
      trackResources: true,
      trackUserInteractions: true,
      version: version
    });
  }

  if (enableSessionTracking) {
    datadogRum.startSessionReplayRecording();
  }
}

/**
 * `console.log` wrapper, logs only when environment is not Production and not Staging
 *
 * @param codeLocation - where in your code this log has been emitted e.g. "Utils:devConsoleLog"
 * @param args - object to log
 */
export function devConsoleLog(codeLocation, ...args) {
  if (!isProductionNodeEnv() && !isStagingNodeEnv()) {
    console.log(`Log @${codeLocation}: `, ...args)
  }
}

/**
 * `console.warn` wrapper, logs only when environment is not Production and not Staging
 *
 * @param codeLocation - where in your code this error has been emitted e.g. "Utils:devConsoleLog"
 * @param args - object to log
 */
export function devConsoleWarn(codeLocation, ...args) {
  if (!isProductionNodeEnv() && !isStagingNodeEnv()) {
    console.warn(`Warn @${codeLocation}: `, ...args)
  }
}

/**
 * `console.error` wrapper, logs an error on datadog when environment is Production or Staging
 *  and locally otherwise
 *
 * @param codeLocation - where in your code this error has been emitted e.g. "Utils:devConsoleLog"
 * @param args - object to log
 */
export function devConsoleError(codeLocation, ...args) {
  if (isProductionNodeEnv() || isStagingNodeEnv()) {
    datadogRum.onReady(() => {
      datadogRum.addError(`Error @${codeLocation}: `, ...args)
    })
  } else {
    console.error(`Error @${codeLocation}: `, ...args)
  }
}

/**
 * Gives custom log capabilities to Elm
 *
 * @param log - serialized log request from elm
 */
export function loggerPort_(log) {
  switch (log.type_) {
    case 'error':
      devConsoleError(log.codeLocation, log.args)
      break
    case 'warn':
      devConsoleWarn(log.codeLocation, log.args)
      break
    case 'log':
      devConsoleLog(log.codeLocation, log.args)
      break
    default:
      devConsoleError('@Logger port function', 'Undefined type value')
  }

}
