const createItemConfig = (itemName, itemValue) => {
  return { name: itemName, value: itemValue };
};

const sendItemResponse = (app, itemName) => {
  const itemValue = window.localStorage.getItem(itemName);
  if (itemValue == null) {
    app.ports.localStorageItemResponse.send(null);
  } else {
    const itemConfig = createItemConfig(itemName, itemValue);
    app.ports.localStorageItemResponse.send(itemConfig);
  }
};

export const localStorageHandler = app => {
  app.ports.localStorageItemRequest.subscribe(itemName => {
    sendItemResponse(app, itemName);
  });

  app.ports.setLocalStorageItem.subscribe(itemConfig => {
    const { name, value } = itemConfig;
    window.localStorage.setItem(name, value);
  });
};
