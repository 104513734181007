import {devConsoleError, devConsoleLog} from "./logger";

// this is an array used as a queue that stores the ContentSquare events that
// cannot yet be processed while ContentSquare is still loading
const pendingContentSquareEvents = [];
let isContentSquareLoaded = false;

export const analyticsHandler = (app) => {
  let userRole;
  const sentEvents = new Set();
  let lastPushedUserRoleHref;

  app.ports.pushFormDataToDataLayer.subscribe((data) => {
    const toPush = [];

    for (let i = 0; i < data.length; ++i) {
      const row = data[i];
      const [key, value] = row;

      if (key === "event") {
        toPush.push(row);
        continue;
      }

      const {isKeyInDataLayer, hasTheSameValue} = checkInDataLayer(key, value);

      if (!isKeyInDataLayer || (!hasTheSameValue && value.length > 0)) {
        toPush.push(row);
      }
    }

    const aggregatedDataToPush = tuplesToObject(toPush);

    if (Object.keys(aggregatedDataToPush).filter(key => key !== "event").length > 0) {
      dataLayer.push(aggregatedDataToPush);
    }
  });

  app.ports.simplePushToDataLayer.subscribe((dataLayerListWithId) => {
    const { id, dataLayerList } = dataLayerListWithId;
    if (typeof id !== "string") {
      devConsoleError("analytics_handler", "Id is expected to be a string");
      return;
    }
    // if the id value is present the event is one-shot, otherwise the same event can be sent more than once
    if (id.length > 0) {
      if (sentEvents.has(id)) {
        devConsoleLog("analytics_handler", "Skipping duplicate event with id: " + id);
        return;
      }
      sentEvents.add(id);
    }
    // add user role before pushing data
    if (!userRole) {
      userRole = getUserRole();
    }
    const aggregatedDataToPush = tuplesToObject(dataLayerList);
    const userRoleKey = "userRole";
    const isUserRolePropertySet = aggregatedDataToPush.hasOwnProperty(userRoleKey);
    if (!isUserRolePropertySet && userRole) {
      aggregatedDataToPush[userRoleKey] = userRole;
    }
    devConsoleLog("analytics_handler", "Pushing event", Object.assign({}, aggregatedDataToPush));
    dataLayer.push(aggregatedDataToPush);
  });

  app.ports.pushUserRole.subscribe(() => {
    if (!userRole) {
      userRole = getUserRole();
    }
    if (!userRole) {
      return;
    }
    // this is to prevent the userrole variable from being sent more than once for
    // the same page
    const href = window.location?.href;
    if (href && href === lastPushedUserRoleHref) {
      return;
    }
    dataLayer.push({"event": "dataLayerUpdate", userRole})
    const successfulContentSquarePush = pushDynamicVariableToContentSquare("userRole", userRole);
    if (successfulContentSquarePush) {
      lastPushedUserRoleHref = href;
    }
  });
}

const pushDynamicVariableToContentSquare = (key, value) => {
  if (!isContentSquareLoaded) {
    pendingContentSquareEvents.push([key, value]);
    return;
  }
  if (!window.hasOwnProperty("_uxa")) {
    devConsoleError("analytics_handler", "_uxa for ContentSquare is missing in window");
    return;
  }
  window._uxa.push(["trackDynamicVariable", {key, value}]);
  return true;
}

const checkInDataLayer = (key, value) => {
  let isKeyInDataLayer = false;
  let hasTheSameValue = false;

  for (let i = 0; i < dataLayer.length; ++i) {
    const data = dataLayer[i];

    if (key in data) {
      isKeyInDataLayer = true
      if (data[key] === value) {
        hasTheSameValue = true
      }
    }
  }
  return {isKeyInDataLayer, hasTheSameValue};
}

const tuplesToObject = tuples => tuples.reduce((result, [key, value]) => ({
  ...result,
  [key]: value
}), {})


export const getUserRole = () => {
  const endUserRole = "enduser";
  const agentRole = "agent";
  if (!("puccio" in window)) {
    devConsoleError("analytics_handler", "Puccio is missing");
    return null;
  }
  if (window.puccio.isUserDataRoleAgentSet()) {
    return agentRole;
  }
  return endUserRole;
};

const processPendingContentSquareEvents = () => {
  while (pendingContentSquareEvents.length > 0) {
    const args = pendingContentSquareEvents.shift();
    if (!Array.isArray(args) || args.length !== 2) {
      devConsoleError("analytics_handler", "The ContentSquare pending events data format is invalid.");
      return;
    }
    const [key, value] = args;
    pushDynamicVariableToContentSquare(key, value);
  }
};

const onContentSquareLoad = () => {
  isContentSquareLoaded = true;
  processPendingContentSquareEvents();
};

export const checkIsContentSquareLoaded = (remainingTries, nextTryDelayMs) => {
  if (remainingTries < 1) {
    return;
  }
  if (window.hasOwnProperty("_uxa")) {
    onContentSquareLoad();
  } else {
    setTimeout(() => {
      checkIsContentSquareLoaded(remainingTries - 1, nextTryDelayMs);
    }, nextTryDelayMs);
  }
};


const extractAnalyticsParameter = (parameterKey, analyticsData) => {
  const isDataFormatValid = Array.isArray(analyticsData) && analyticsData.every(el => Array.isArray(el) && el.length === 2);
  const targetKeyValuePair = isDataFormatValid ? analyticsData.find(([k, _]) => k === parameterKey) : null;

  if (!isDataFormatValid) {
    devConsoleError("analytics_handler", "The analytics data format is invalid.");
  }

  if (targetKeyValuePair) {
    const [_, parameterValue] = targetKeyValuePair;
    return parameterValue;
  }
};
