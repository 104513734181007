require("intersection-observer");

import {Elm} from "./Main.elm";
import braintreePayment from "./_static/js/braintree-payment";
import {
  analyticsHandler,
  checkIsContentSquareLoaded,
} from "./_static/js/analytics_handler";
import {getReferral, getParameterByName} from "./_static/js/referral_handler";
import {localStorageHandler} from "./_static/js/local_storage_handler";
import {isStagingNodeEnv, isProductionNodeEnv} from "./_static/js/utils.js";
import {bootDatadog, loggerPort_} from "./_static/js/logger";
import * as Authenticator from "@rfq-dts-authenticator/RFQDTS/Http/Authenticator";
import * as CookieBot from "./CookieBot/CookieBot"
import {FlagshipIO} from "./FlagshipIO/FlagshipIO";
import Cookie from "js-cookie";
import {v4 as uuidv4} from 'uuid'

const flagshipIO = new FlagshipIO({
  apiKey: process.env.ELM_APP_FLAGSHIP_API_KEY,
  envId: process.env.ELM_APP_FLAGSHIP_ENVIRONMENT_ID,
  logHitSending: !(process.env.IS_PRIVATE_LOG_ENVIRONMENT === 'true'),
  visitorId: getPersistentIdentifier(process.env.IS_CROSS_DOMAIN_COOKIE_ENABLED === 'true', '.prima.it'),
  visitorInitialContext: {isMobile: isMobile()}
})

flagshipIO.getFlag('domus-fe-configuration', {})
  .then((flags) => boot(flags))
  .catch(() => boot({}))

bootDatadog({
  applicationId: "47955611-d4fb-4245-b162-251326b3b17d",
  clientToken: "pub38789b067dffc9e4d3c40cd864654f0a",
  service: "domus",
  env: process.env.ENV,
  version: process.env.DRONE_COMMIT_SHA.substring(
    process.env.DRONE_COMMIT_SHA.length - 10
  ),
  // This is for the Reply feature. Activate to record user's interactions at the moment errors happen
  enableSessionTracking: false,
});

/**
 * Boot elm after dynamic flag fetching
 * @param {object|null} dynamicFlags - any possible value fetched from remote
 */
function boot(dynamicFlags) {
  const array = new Uint32Array(1);
  const crypto = window.crypto || window.msCrypto;
  const randomNumber = crypto.getRandomValues(array)[0];

  const getAndRemoveNonce = () => {
    const nonce = getParameterByName("nonce");
    const url = new URL(window.location);
    if (url.searchParams) {
      url.searchParams.delete("nonce");
    }
    if (nonce) {
      history.replaceState(null, null, url);
    }
    return nonce;
  };

  const tokenConfig = () => {
    let jwtToken = getParameterByName("token");

    if (jwtToken != null) {
      let tokenConfig = {
        token: jwtToken,
        refreshToken: null,
        tokenRetrievedFromUrl: true,
      };

      if (window.sessionStorage) {
        window.sessionStorage.setItem("tokenConfig", JSON.stringify(tokenConfig));
      }
      return tokenConfig;
    }

    if (window.sessionStorage) {
      let tokenConfig = window.sessionStorage.getItem("tokenConfig");

      if (tokenConfig != null) {
        let parsedTokenConfig = JSON.parse(tokenConfig);
        parsedTokenConfig.tokenRetrievedFromUrl = false;
        return parsedTokenConfig;
      }
    }

    return null;
  };

  let app = Elm.Main.init({
    node: document.getElementById("root"),
    flags: {
      staticFlags: {
        apiUrl: process.env.ELM_APP_API_URL,
        challengeProviderUrl: process.env.ELM_APP_CHALLENGE_PROVIDER_URL,
        cookiePageUrl: process.env.ELM_APP_COOKIE_PAGE_URL,
        downloadUrl: process.env.ELM_APP_DOWNLOAD_URL,
        intermediariUrl: process.env.ELM_APP_INTERMEDIARI_URL,
        isMobile: isMobile(),
        liraUrl: process.env.ELM_APP_LIRA_URL,
        loginPageUrl: process.env.ELM_APP_LOGIN_PAGE_URL,
        mastrotaUrl: process.env.ELM_APP_MASTROTA_URL,
        nonce: getAndRemoveNonce(),
        primaUrl: process.env.ELM_APP_PRIMA_URL,
        referral: getReferral(),
        seed: randomNumber,
        tokenConfig: tokenConfig()
      },
      // todo: make this dynamic once flagship has been set up
      dynamicFlags: dynamicFlags
    },
  });

  flagshipIO.mount(app)
  braintreePayment(app);
  analyticsHandler(app);
  localStorageHandler(app);

  CookieBot.bindElmApp(app);

  if (isStagingNodeEnv() || isProductionNodeEnv()) {
    // check whether ContentSquare has been successfully loaded every 200ms at most 50 times,
    // after that ContentSquare loading is considered unsuccessful
    const remainingTries = 50;
    const nextTryDelayMs = 200;
    checkIsContentSquareLoaded(remainingTries, nextTryDelayMs);
  }

  app.ports.scrollToElement.subscribe(function (selector) {
    const element = document.querySelector(selector);
    const fromTop = window.pageYOffset + element.getBoundingClientRect().top;

    if (window.innerWidth <= 576) {
      const yDestination = fromTop - 50;
      window.scrollTo(0, yDestination);

      setTimeout(function () {
        window.scrollTo(0, yDestination);
      }, 500); // mobile keyboard slide in
    }

    element.scrollIntoView({behavior: "smooth", block: "nearest"});
  });

  app.ports.storeTokenConfig.subscribe((tokenConfig) => {
    if (window.sessionStorage) {
      window.sessionStorage.setItem("tokenConfig", JSON.stringify(tokenConfig));
    }
  });

  app.ports.toggleClass.subscribe((toggleClassConfigs) => {
    for (const {isAdding, className, selector} of toggleClassConfigs) {
      const domElement = document.querySelector(selector);
      if (!domElement) {
        return;
      }
      if (isAdding) {
        domElement.classList.add(className);
      } else {
        domElement.classList.remove(className);
      }
    }
  });

  app.ports.pyxisStylesheets.subscribe((isNewPyxisDisabled) => {
    const documentStyleSheetsArray = Array.from(document.styleSheets);
    const styleSheetsArray = documentStyleSheetsArray.filter(
      (el) => typeof el?.href === "string"
    );
    // the optional group in the patterns is the content hash added by the Webpack's prod config
    const oldPyxisPattern = /oldPyxis(?:\.[a-zA-Z0-9]+\.chunk)?\.css$/;
    const pyxisPattern = /pyxis(?:\.[a-zA-Z0-9]+\.chunk)?\.css$/;
    const oldPyxisStylesheet = styleSheetsArray.find((obj) =>
      oldPyxisPattern.test(obj.href)
    );
    const pyxisStylesheet = styleSheetsArray.find((obj) =>
      pyxisPattern.test(obj.href)
    );
    if (!oldPyxisStylesheet || !pyxisStylesheet) {
      console.error("Missing Pyxis stylesheet(s).");
      return;
    }
    oldPyxisStylesheet.disabled = !isNewPyxisDisabled;
    pyxisStylesheet.disabled = isNewPyxisDisabled;
  });

  app.ports.loggerPort_.subscribe(loggerPort_);

  Authenticator.subscriber(app);
}


/**
 * Tests again user agent string if it is associated to a known mobile device.
 * WARNING: this method may or may not work accordingly to browser settings as device fingerprinting is explicitly
 * hindered by tracking prevention policies
 *
 * @returns whether the user agent is associated to a mobile device
 */
export function isMobile() {
  const windowWidth = window.innerWidth;
  const mobileBreakpoint = 768;
  const breakpointCheck = windowWidth < mobileBreakpoint;
  // this is a workaround to check for mobile devices with a large screen
  const maxTouchPointsCheck = navigator.maxTouchPoints > 1;
  return breakpointCheck || maxTouchPointsCheck;

}

/**
 * Gets the browser sticky (1 year) identifier stored in a cookie named wu_uuid.
 * This function gets current wu_uuid value, if not present for some unknown reason then a new value is generated and stored
 *
 * @param {boolean} isCrossDomainCookieEnabled - whether cookie can be applied in cross-domain mode (env based)
 * @param {string} crossDomain - the domain that will be used whe cross domain is enabled eg. '.prima.it'
 * @returns {string} the uuid4 value
 */
export function getPersistentIdentifier(isCrossDomainCookieEnabled, crossDomain) {
  const browserIdentifierCookieName = 'wu_uid'
  let browserIdentifier = Cookie.get(browserIdentifierCookieName)

  if (!browserIdentifier) {
    browserIdentifier = uuidv4().toString()

    const nextY = new Date()
    nextY.setFullYear(nextY.getFullYear() + 1)

    Cookie.set(browserIdentifierCookieName, browserIdentifier, {
      domain: isCrossDomainCookieEnabled ? crossDomain : undefined,
      expires: nextY,
      sameSite: isCrossDomainCookieEnabled ? 'none' : 'lax',
      secure: isCrossDomainCookieEnabled
    })
  }

  return browserIdentifier
}