import _ from 'lodash'
import { devConsoleError } from "./logger.js";

const braintreeClient = require('braintree-web/client');
const braintreeHostedFields = require('braintree-web/hosted-fields');
const braintreePaypal = require('braintree-web/paypal');
const braintree3DSecureClient = require('braintree-web/three-d-secure');

let use3dSecure = false;
let hostedFieldInstance = null;
let braintreeClientInstance = null;

export default (app) => {
    app.ports.braintree3DSSetup.subscribe(function (token) {
        console.debug("Try to Initialize braintree with 3ds");

        if (braintreeClientInstance !== null) {
            braintreeClientInstance.teardown()
                .then(() => {
                    initialize()
                })
                .catch(error => {
                    devConsoleError('Error @BraintreeSetup with 3DS Teardown:' + error.message);
                    app.ports.creditCardReady.send(false);
                    sendErrorToElm("setupBraintreeClientInstance", error);
                })
        } else {
            initialize()
        }

        // Before next browser repaint
        function initialize() {
            window.requestAnimationFrame(() => {
                setTimeout(() => {
                    setupBraintree3DS(token)
                }, 500)
            })
        }
    });

    /*
      Braintree setup
      @params [String] braintreeToken - the token
     */
    const setupBraintree = braintreeToken => {
        use3dSecure = false
        braintreeClient.create({ authorization: braintreeToken }, braintreeClientReady)
    };

    const setupBraintree3DS = (braintreeToken) => {
        use3dSecure = true;
        braintreeClient
            .create({
                authorization: braintreeToken,
            })
            .then((clientInstance) => {
                braintreeClientInstance = clientInstance;
                return braintree3DSecureClient.create({
                    version: 2, // Will use 3DS 2 whenever possible
                    client: clientInstance,
                });
            })
            .then((threeDSecureInstance) => {
                window.threeDSecure = threeDSecureInstance;
                braintreeClientReady(
                    braintreeClientInstance == null
                        ? new Error('Unable to initialize Braintree with 3dsecure')
                        : null,
                    braintreeClientInstance
                );
            })
            .catch(function (error) {
                //console.error('Error: ', err);
                sendErrorToElm("setupBraintree3DS", error);
                devConsoleError('Error @BraintreeSetup with 3DS:' + error.message);
                setupBraintree(braintreeToken);
            });
    };

    /*
      Callback of Braintree's setup
      @params [Object] err - the Braintree's error
      @params [Object] clientInstance - the Braintree's instance returned after setup
     */
    const braintreeClientReady = (error, clientInstance) => {
        // Die on setup error
        if (error) {
            //app.ports.creditCardReady.send(false);
            devConsoleError('Error @BraintreeClientReady:' + error.message);
            sendErrorToElm("braintreeClientReady", error);
            return
        }

        braintreeClientInstance = clientInstance
        // Compose Iframes and style them
        if (hostedFieldInstance !== null) {
            hostedFieldInstance.teardown()
                .then(() => {
                    hostedFieldInstance = null
                    initializeHostedFields()
                })
                .catch(error => {
                    app.ports.creditCardReady.send(false);
                    devConsoleError('Error @BraintreeClientReady Hosted fields Teardown:' + error.message);
                    sendErrorToElm("braintreeClientInstance", error);
                })
        } else {
            initializeHostedFields()
        }

        // Create PayPal's iframe
        braintreePaypal.create(
          {
            client: clientInstance,
          },
          paypalSettedUp
        );

        function initializeHostedFields() {
            braintreeHostedFields.create({
                client: clientInstance,
                styles: {
                    'input': {
                        'color': '#7e8890',
                        'font-size': '16px',
                        'font-weight': 'lighter',
                        'transition': 'color 160ms linear',
                        'padding': '10px 0'
                    },
                    ':focus': {
                        'color': '#4d5969'
                    },
                    '.valid': {
                        'color': '#1db1aa'
                    },
                    '.invalid': {
                        'color': '#e74c3c'
                    },
                },
                fields: {
                    number: { selector: '#card-number' },
                    cvv: { selector: '#cvv' },
                    expirationMonth: { selector: '#expiration-month' },
                    expirationYear: { selector: '#expiration-year' }
                }
            }, creditCardSettedUp)
        }
    };

    /*
      Callback of Braintree's DOM render
      @params [Object] err - the Braintree's error
      @params [Object] clientInstance - the Braintree's DOM
     */
    const creditCardSettedUp = (error, braintreeHostedFieldsInstance) => {
        if (error) {
            //app.ports.creditCardReady.send(false);
            devConsoleError('Error @BraintreeCreditCardSettedUp:' + error.message);
            sendErrorToElm("creditCardSettedUp " + braintreeHostedFieldsInstance, error);
            return;
        }
        // Apply events to Braintree's DOM nodes
        braintreeHostedFieldsInstance.on('validityChange', braintreeValidationResponse)
        braintreeHostedFieldsInstance.on('blur', braintreeValidationResponse)

        hostedFieldInstance = braintreeHostedFieldsInstance

        // Make subscriptions to Elm's actions
        app.ports.payWithCreditCard.subscribe(() => {
            return hostedFieldInstance.tokenize(tokenized())
        })

        // Notify Payment app that the DOM can be now manipulated
        app.ports.creditCardReady.send(true)
    }

     /*
      Callback of PayPal's DOM render
      @params [Object] err - the PayPal's error
      @params [Object] paypalInstance - the PayPal's DOM
     */
      const paypalSettedUp = (error, paypalInstance) => {
        if (error) {
          devConsoleError('Error @PayPalSettedUp:' + error.message);
          sendErrorToElm("paypalSettedUp " + paypalInstance, error);
          app.ports.paypalReady.send(false);
          return;
        }

        // Make subscriptions to Elm's actions
        app.ports.payWithPayPal.subscribe(payWithPayPal(paypalInstance));

        // Notify Payment app that the DOM can be now manipulated
        app.ports.paypalReady.send(true);
      };

    /*
      Callback of Braintree's nodes validation
      @params [Object] response - the Braintree's validation response
     */
    const braintreeValidationResponse = (response) => {
        // Transform the Braintree response into an ELM compliant datatype
        // then fire it through the port
        app.ports.validateBraintreeData.send(mapBraintreeResponse(response))
    };

    /*
      Callback of Braintree's nodes validation
      @params [Object] response - the Braintree's validation response
     */
    const mapBraintreeResponse = response => {
        let fields = response.fields
        let keys = _.keys(fields)

        //console.log("fields: ", fields)
        //console.log("keys: ", keys)

        return _.map(keys, key => {
            let braintreeNode = fields[key]
            let id = braintreeNode.container.id
            let isValid = braintreeNode.isValid
            let isEmpty = braintreeNode.isEmpty

            /* If the expirationYear is not valid we must
               invalidate the expirationMonth field.
               This must be done for design purpose.
            **/
            if (key == 'expirationMonth') {
                isValid = isValid && (fields.expirationYear.isValid || fields.expirationYear.isEmpty)
            }

            return {
                id: id,
                isValid: isValid,
                isPristine: isEmpty
            }
        })
    };

    /*
      Payment with PayPal was requested.
      Retrieve the nonce and then send it through Elm ports
     */
    const payWithPayPal = paypalInstance => {
        return token => {
            paypalInstance.tokenize({flow: 'vault'}, tokenized())
        }
    };

    const tokenized = action => {
        return (tokenizeErr, payload) => {
            // Payment request has failed
            if (tokenizeErr) {
                console.error('Braintree tokenize error', tokenizeErr.message);
                devConsoleError('Error @Braintree Tokenized: ' + tokenizeErr.message);
                sendErrorToElm("braintreeTokenizeError", tokenizeErr);

                if (tokenizeErr.code == 'PAYPAL_POPUP_CLOSED') {
                  app.ports.paypalPopupClosed.send(true);
                }

                return
            }

            // Payment nonce received. Send it through the port
            if (use3dSecure && "CreditCard" == payload.type) {
                app.ports.threeDSReceivedPayload.send({ nonce: payload.nonce, bin: payload.details.bin, outcome: null })
            } else {
                app.ports.braintreeReceivedNonce.send(payload.nonce)
            }
        }
    };

    app.ports.verify3DSCard.subscribe(({ amount, config }) => {
        window.threeDSecure.verifyCard({
            amount: "" + amount,
            nonce: config.nonce,
            bin: config.bin,
            challengeRequested: true,
            email: config.email,
            mobilePhoneNumber: config.phoneNumber,
            billingAddress: {
                givenName: config.billingAddress.givenName,
                surname: config.billingAddress.surname,
                phoneNumber: config.billingAddress.phoneNumber,
                streetAddress: config.billingAddress.streetAddress,
                locality: config.billingAddress.locality,
                region: config.billingAddress.region,
                postalCode: config.billingAddress.postalCode,
                countryCodeAlpha2: config.billingAddress.countryCode
            },
            onLookupComplete: (data, next) => {
                next()
            }
        })
            .then((response) => {
                // Handle response
                console.debug('Received nonce, payment attempt');

                app.ports.threeDSVerified.send({
                    nonce: response.nonce,
                    bin: response.details.bin,
                    status: response.threeDSecureInfo.status
                })
            }).catch((error) => {
                // Handle error
                console.debug('3DS exception, try to fallback to plain braintree');
                devConsoleError('Error @Braintree 3DS verify card:' + error.message);
                sendErrorToElm("sendThreeDSVerified", error);
            })
    });

    const sendErrorToElm = (context, error) => {
        let errorEvent = {
            context: context,
            error: error.message,
            code: error.code
        };

        if (error && error.details && error.details.originalError) {
            errorEvent.error = error.details.originalError.message;
            if (error.details.originalError.details
                && error.details.originalError.details.originalError
                && error.details.originalError.details.originalError.error) {
                errorEvent.error = error.details.originalError.details.originalError.error.message;
            }
        }

        if (errorEvent.error === undefined) {
            errorEvent.error = "";
        }

        app.ports.notifyErrorEvent.send(errorEvent);
    }
}
